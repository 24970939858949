import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import getFeeByName from "../../helpers/getFeeByName";
import getProductDataByName from "../../helpers/getProductDataByName";
import BestBanksDefault from "../../components/best-banks/best-banks-default";

const AccountFees = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allStrapiProducts {
        nodes {
          Name
          ProductData {
            name
            value
          }
        }
      }
      allStrapiFees {
        nodes {
          Amount
          Name
        }
      }
      miscValueDates: allStrapiMiscValues(filter: { Name: { eq: "Bank Fees - Date" } }) {
        nodes {
          Value
        }
      }
    }
  `);
  const allStrapiProducts = data.allStrapiProducts;
  const feesDate = data.miscValueDates.nodes[0].Value;

  const title = "Personal Savings Accounts Fee Schedule";
  const description =
    "Learn more about the fees and service charges associated with our personal savings accounts here.";
  const SEOData = {
    title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/account-fees"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      active: true,
      title: "Fees"
    }
  ];

  /**
   *
   * @param {string} feeName
   * @param {?function} dataTransform
   * @returns {?JSX.Element}
   */
  function renderFeeRow(fontWeightBold, textIndentation, id, feeName, dataTransform = null) {
    let feeRecord = getFeeByName(data.allStrapiFees, feeName);
    if (!feeRecord) {
      return null;
    }
    if (dataTransform && typeof dataTransform === "function") {
      feeRecord = dataTransform(feeRecord);
    }
    return (
      <tr>
        <td
          id={id}
          style={{ width: "66%" }}
          className={`${textIndentation ? "pl-4" : ""} ${fontWeightBold ? "font-weight-bold" : ""}`}
          dangerouslySetInnerHTML={{ __html: feeRecord.Name }}
        />
        <td
          id={`${id}-value`}
          style={{ width: "34%" }}
          dangerouslySetInnerHTML={{ __html: feeRecord.Amount.replace(/\u200B/g, "") }}
        />
      </tr>
    );
  }

  /**
   *
   * @param {Object} productDataRow
   * @param {?Function} dataTransform
   * @returns {JSX.Element}
   */

  function renderProductDataRow(id, productDataRow, dataTransform = null) {
    if (productDataRow && productDataRow.name !== "") {
      if (typeof dataTransform === "function") {
        productDataRow = dataTransform(productDataRow);
      }
      return (
        <tr>
          <td
            id={id}
            className="pl-4"
            style={{ width: "66%" }}
            dangerouslySetInnerHTML={{ __html: productDataRow.name }}
          />
          <td id={`${id}-value`} style={{ width: "34%" }} dangerouslySetInnerHTML={{ __html: productDataRow.value }} />
        </tr>
      );
    }
  }

  /**
   * @param {string} productName
   * @param {[]} productNamesToInclude
   * @return {[]}
   */
  function renderProductRows(productName, productNamesToInclude = []) {
    const rows = [];
    for (let i = 0; i < productNamesToInclude.length; i++) {
      const productDataRow = getProductDataByName(allStrapiProducts, productName, productNamesToInclude[i]);
      const id = (productName + "-" + productDataRow.name)
        .toLowerCase()
        .replace(/ - +/g, "-")
        .replace(/ +/g, "-")
        .replace("(", "")
        .replace(")", "");
      if (productDataRow && productDataRow.name !== "") {
        rows.push(renderProductDataRow(id, productDataRow));
      }
    }
    return rows;
  }

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1>Schedule of Consumer Fees &amp; Service Charges</h1>
            <p className="font-weight-bold">
              Effective <span id="account-fees-effective-date">{feesDate}</span>
            </p>
            <p id="account-fees-notice">
              All fees and service charges are subject to change. We'll mail you a notice 30 days before any increase in
              rates or fee changes take effect.
            </p>
            <h2 id="account-fees-checking-accounts" className="text-success font-weight-semi-bold">
              Checking Accounts
            </h2>

            <div className={`table-responsive`}>
              <table className={`table`}>
                <tbody className="tbody-white">
                  {/* Free Checking */}
                  <tr className="bg-secondary">
                    <td colSpan="2" id="fc-insuffient-funds">
                      <Link
                        id="free-checking-link"
                        to="/personal-banking/free-checking-account"
                        className="text-white font-weight-bold"
                      >
                        *Free Checking
                      </Link>
                    </td>
                  </tr>
                  {renderProductRows("Free Checking", ["Initial Deposit"])}
                  <tr>
                    <td colSpan="2" className="pl-4">
                      <em>No Minimum Balance Service Charge</em>
                    </td>
                  </tr>

                  {/* Rewards Checking */}
                  <tr className="bg-secondary">
                    <td colSpan="2" id="account-fees-rewards-checking">
                      <Link
                        id="rewards-checking-link"
                        to="/personal-banking/checking-account/rewards-checking"
                        className="text-white font-weight-bold"
                      >
                        Rewards Checking
                      </Link>
                    </td>
                  </tr>
                  {renderProductRows("Rewards Checking", ["Initial Deposit"])}
                  <tr>
                    <td colSpan="2" className="pl-4">
                      <em id="rewards-checking-disclaimer">Includes Rewards</em>
                    </td>
                  </tr>
                  {renderProductRows("Rewards Checking", ["Service Charge"])}

                  {/* Premium Rewards Checking */}
                  <tr className="bg-secondary">
                    <td colSpan="2" id="account-fees-premium-rewards-checking">
                      <Link
                        id="premium-rewards-checking-link"
                        to="/personal-banking/checking-account/rewards-checking"
                        className="text-white font-weight-bold"
                      >
                        Premium Rewards Checking
                      </Link>
                    </td>
                  </tr>
                  {renderProductRows("Premium Rewards Checking", ["Initial Deposit"])}
                  <tr>
                    <td colSpan="2" className="pl-4">
                      <em id="premium-rewards-checking-disclaimer">Includes Premium Rewards</em>
                    </td>
                  </tr>
                  {renderProductRows("Premium Rewards Checking", ["Service Charge"])}

                  {/* Interest Checking */}
                  <tr className="bg-secondary">
                    <td colSpan="2" id="account-fees-fresh-start">
                      <Link
                        id="interest-checking-link"
                        to="/personal-banking/checking-account/interest-rewards-checking"
                        className="text-white font-weight-bold"
                      >
                        Interest Checking<sup>&dagger;</sup>
                      </Link>
                    </td>
                  </tr>
                  {renderProductRows("Interest Checking", ["Initial Deposit"])}

                  <tr>
                    <td colSpan="2" id="interest-checking-disclaimer-1" className="pl-4">
                      <em>Includes Premium Rewards</em>
                    </td>
                  </tr>
                  {renderProductRows("Interest Checking", [
                    "Minimum Balance to Waive Service Charge",
                    "Service Charge"
                  ])}

                  <tr>
                    <td colSpan="2" id="interest-checking-disclaimer-2" className="pl-4">
                      No Minimum Balance to Earn Interest
                    </td>
                  </tr>

                  <tr className="bg-secondary">
                    <td colSpan="2" id="account-fees-fresh-start">
                      <Link
                        id="fresh-start-link"
                        to="/personal-banking/fresh-start-second-chance-account"
                        className="text-white font-weight-bold"
                      >
                        Fresh Start (BankOn Certified)
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" className="pl-4">
                      <em id="fresh-start-disclaimer">Not eligible for checks or starter checks</em>
                    </td>
                  </tr>

                  {renderProductRows("Fresh Start (Bank On Certified)", ["Initial Deposit", "Service Charge"])}
                </tbody>
              </table>
            </div>

            <h2 className="text-success font-weight-semi-bold">Savings &amp; Money Market</h2>

            <div className={`table-responsive`}>
              <table className={`table`}>
                <tbody>
                  {/* Money Market */}
                  <tr className="bg-secondary">
                    <td colSpan="2">
                      <Link
                        id="money-market-link"
                        to="/personal-banking/savings-account"
                        className="text-white font-weight-bold"
                      >
                        Money Market<sup>&dagger;</sup>
                      </Link>
                    </td>
                  </tr>
                  {renderProductRows("Money Market", [
                    "Initial Deposit",
                    "Minimum Balance to Waive Service Charge",
                    "Service Charge",
                    "Minimum Balance to Earn Interest"
                  ])}

                  {/* Gold Money Market */}
                  <tr className="bg-secondary">
                    <td colSpan="2">
                      <Link
                        id="gold-money-market-link"
                        to="/personal-banking/savings-account"
                        className="text-white font-weight-bold"
                      >
                        Gold Money Market<sup>&dagger;</sup>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" id="gold-money-market-disclaimer-1" className="pl-4">
                      <em>Promotion may be discontinued at any time</em>
                    </td>
                  </tr>
                  {renderProductRows("Gold Money Market", [
                    "Initial Deposit",
                    "Minimum Balance to Waive Service Charge",
                    "Service Charge",
                    "Minimum Balance to Earn Interest"
                  ])}

                  {/* High Yield Money Market */}
                  <tr className="bg-secondary">
                    <td colSpan="2">
                      <Link
                        id="high-yield-money-market-link"
                        to="/personal-banking/savings-account"
                        className="text-white font-weight-bold"
                      >
                        High Yield Money Market<sup>&dagger;</sup>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" id="high-yield-money-market-disclaimer-1" className="pl-4">
                      <em>Promotion may be discontinued at any time</em>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" id="high-yield-money-market-disclaimer-2" className="pl-4">
                      <em>Not Available for IRAs or Business Clients</em>
                    </td>
                  </tr>
                  {renderProductRows("High Yield Money Market", [
                    "Initial Deposit",
                    "Minimum Balance to Waive Service Charge",
                    "Service Charge"
                  ])}

                  {/* Savings */}
                  <tr className="bg-secondary">
                    <td colSpan="2">
                      <Link
                        id="savings-link"
                        to="/personal-banking/savings-account"
                        className="text-white font-weight-bold"
                      >
                        Savings<sup>&dagger;</sup>
                      </Link>
                    </td>
                  </tr>
                  {renderProductRows("Savings", [
                    "Initial Deposit",
                    "Minimum Balance to Waive Service Charge",
                    "Service Charge",
                    "Minimum Balance to Earn Interest"
                  ])}

                  <tr className="bg-secondary">
                    <td colSpan="2" className="text-white font-weight-bold">
                      Start Savings<sup>&dagger;</sup>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" id="start-savings-disclaimer-1" className="pl-4">
                      <em>Requires a checking account to open.</em>
                    </td>
                  </tr>
                  {renderProductRows("Start Savings", ["Initial Deposit"])}
                  <tr>
                    <td id="start-savings-disclaimer-2" colSpan="2" className="pl-4">
                      <em>No Minimum Balance Service Charge</em>
                    </td>
                  </tr>
                  <tr>
                    <td id="start-savings-disclaimer-3" colSpan="2" className="pl-4">
                      <em>No Minimum Balance to Earn Interest</em>
                    </td>
                  </tr>
                  {/* Minor Savings */}
                  <tr className="bg-secondary">
                    <td colSpan="2">
                      <Link
                        id="minor-savings-link"
                        to="/personal-banking/savings-account"
                        className="text-white font-weight-bold"
                      >
                        Minor Savings<sup>&dagger;</sup>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" id="minor-savings-disclaimer" className="pl-4">
                      <em>No Minimum Balance Service Charge - Under Age 18</em>
                    </td>
                  </tr>
                  {renderProductRows("Minor Savings", ["Initial Deposit", "Minimum Balance to Earn Interest"])}

                  {/* Save at School */}
                  <tr className="bg-secondary">
                    <td colSpan="2">
                      <Link
                        id="save-at-school-savings-link"
                        to="/personal-banking/savings-account/youth-savings-account/save-at-school"
                        className="text-white font-weight-bold"
                      >
                        Save at School Savings<sup>&dagger;</sup>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" id="save-at-school-disclaimer" className="pl-4">
                      <em>Only available to elementary school aged minors part of an active Save at School program</em>
                    </td>
                  </tr>
                  {renderProductRows("Save at School Savings", [
                    "Initial Deposit",
                    "Minimum Balance to Waive Service Charge"
                  ])}
                </tbody>
              </table>
            </div>

            <h2 className="text-success font-weight-semi-bold">In Person Payments</h2>
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr className="bg-secondary text-white">
                    <td colSpan="2">
                      <strong>Cashier's Checks</strong>
                    </td>
                  </tr>
                  {/* update in Strapi */}
                  {renderFeeRow(false, true, "cashiers-checks-clients-only", "Cashier's Checks")}
                  <tr className="bg-secondary text-white">
                    <td colSpan="2">
                      <strong>Collections and Foreign Check Processing</strong>
                    </td>
                  </tr>
                  {renderFeeRow(false, true, "foreign-all-check-collections", "All Check Collections")}
                  {renderFeeRow(false, true, "canadian-check-processing", "Canadian Check Processing")}
                  {renderFeeRow(
                    false,
                    true,
                    "all-other-foreign-check-processing",
                    "All Other Foreign Check Processing"
                  )}

                  <tr className="bg-secondary text-white">
                    <td colSpan="2">
                      <strong>External Transfers</strong>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" className="pl-4">
                      <em>Must be scheduled by 9:30am PT for same day guarantee</em>
                    </td>
                  </tr>
                  {/* update in Strapi */}
                  {renderFeeRow(false, true, "in-branch-external-transfers", "Pre-Authorized Transaction - Same Day")}

                  <tr className="bg-secondary">
                    <td colSpan="2" className="text-white">
                      <strong>Wire Transfers</strong>
                    </td>
                  </tr>
                  {renderFeeRow(false, true, "incoming-wire-transfers", "Incoming")}
                  {renderFeeRow(false, true, "outgoing-wire-transfers", "Outgoing—Domestic")}
                  {renderFeeRow(false, true, "outgoing-wire-transfers-international", "Outgoing—International")}
                </tbody>
              </table>
            </div>

            <h2 className="text-success font-weight-semi-bold">Other Fees &amp; Services</h2>

            <div className={`table-responsive`}>
              <table className={`table`}>
                <tbody>
                  {renderFeeRow(true, false, "card-replacement-order-service", "Card Replacement—Standard Service")}
                  {renderFeeRow(
                    true,
                    false,
                    "card-replacement-expedited-service",
                    "Card Replacement—Expedited Service"
                  )}
                  {renderFeeRow(true, false, "check-images-monthly-statement", "Check Images with Monthly Statement")}
                  {renderFeeRow(true, false, "inactive-account", "Inactive Account Fee—Checking only", (feeRecord) => {
                    feeRecord.Name =
                      feeRecord.Name +
                      "<br/><em class='font-weight-normal'>Assessed after 270 days without activity</em>";
                    return feeRecord;
                  })}
                  {renderFeeRow(true, false, "legal-processing", "Legal Processing")}
                  {renderFeeRow(true, false, "loan-payment-returned-check-fee", "Loan Payment—Returned Check Fee")}
                  {renderFeeRow(true, false, "nonsufficient-funds-fee", "Nonsufficient Funds Fee (NSF)")}
                  {renderFeeRow(true, false, "nsf-daily-maximum", "NSF Daily Maximum")}
                  {renderFeeRow(true, false, "research-fee", "Research Fee (1 Hour Minimum)", (feeRecord) => {
                    feeRecord.Name = feeRecord.Name + "<sup>&Dagger;</sup>";
                    return feeRecord;
                  })}
                  <tr>
                    <td id="box-availability" colSpan="2">
                      <strong>Box Availability**</strong>
                      <br />
                      <em>
                        Safe deposit box size and availability vary by location. Please ask a{" "}
                        <Link to="/locations#branch-search-section" id="sdb-branch-search-link">
                          branch representative
                        </Link>{" "}
                        for a quote.
                      </em>
                    </td>
                  </tr>
                  {renderFeeRow(true, false, "safe-deposit-box-key-deposit", "Safe Deposit Box Key Deposit")}
                  {renderFeeRow(true, false, "safe-deposit-box-drilling", "Safe Deposit Box Drilling")}

                  {renderFeeRow(true, false, "stop-payment", "Stop Payment")}
                  {renderFeeRow(
                    true,
                    false,
                    "visa-international-transaction-fee",
                    "VISA International Transaction Fee"
                  )}
                </tbody>
              </table>
            </div>

            <p className="text-muted text-sm" id="free-checking-disclaimer">
              *Nonsufficient Funds Fees may apply.
            </p>

            <p id="safe-deposit-box-disclaimer" className="text-muted text-sm">
              **Safe Deposit Box Annual Rent Schedule available upon request.
            </p>
            <p id="savings-disclaimer" className="text-muted text-sm">
              <sup>&dagger;</sup>Interest is compounded daily and paid monthly.
            </p>
            <p id="research-fee-disclaimer" className="text-muted text-sm mb-0">
              <sup>&Dagger;</sup>Assessed only if request will take at least one hour. Includes cost for statement,
              deposit ticket and/or check copies.
            </p>
          </div>
        </div>
      </section>
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default AccountFees;
